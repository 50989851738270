<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
  <LineChartGenerator v-if="isReady"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  </div>
</template>
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

// import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
// import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import {
  apiCall, unitSign
} from '@/common/utils';
// import moment from 'moment';
import qs from "querystring";

export default {
  name: 'GraphInout',
  components: {
    LineChartGenerator
    // hexToRgba,
    // CustomTooltips
  },

  props: {
    tid: { type: String, default: null },
    volUnit: { type: String, default: 'l' },
    fromDts: { type: String , default: null },
    toDts: { type: String , default: null },

    chartId: {type: String, default: 'line-chart'},
    datasetIdKey: {type: String, default: 'label'},
    width: {type: Number, default: 400},
    height: {type: Number, default: 400},
    cssClasses: {default: 'small', type: String},
    styles: {type: Object, default: () => {}},
    plugins: {type: Array, default: () => []}
  },
  data(){
    return {
      isReady: false,
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      tankMap: this.$store.state.tanks['codes'],
      chartData : {},
      chartOptions: {},
    }
  },
  created(){
    console.log( '--------------- GraphInout created()  --------------- volume unit===>', this.volUnit);
  },
  async mounted () {
    console.log( '--------------- GraphInout mounted() ---------------');
    await this.renderChart();
  },
  methods: {
    async renderChart(){
      console.log( '--------------- GraphInout renderChart() ---------------');
      // const {color} = this.tankMap[this.tid];
      try{
        let dataArr, labels;
        this.isReady = false;
        const qry = qs.stringify(  {
          fromDts: this.fromDts,
          toDts: this.toDts,
          volUnit: this.volUnit
        });

        const rs = await apiCall( 'GET', `/api/graph/inout/${this.tid}?${qry}`);

        if(rs.code===200){
          dataArr = rs.result.vals;
          labels = rs.result.days;

          this.chartData = {
            labels: labels,
            datasets: [
              {
                data: dataArr,
                label: `재고량 (${unitSign(this.volUnit)})`,
                fill: 'true',
                borderWidth: 1,
                backgroundColor: this.tankMap[this.tid].color,
              }
            ],
          }

          this.chartOptions =  {
            responsive: true,
            maintainAspectRatio: false,
          };

          this.isReady = true;

          console.log(  'result --->', rs.result)
          // console.log( this.chartData)
          // console.log( this.chartOptions )

        }

      }catch(err){
        console.error(err);
      }

    }
  },

  watch: {
    /*tid: function(val){
      console.log( '--------------- child: watch( tankId ) LineByTank ---------------: '+val);
      this.renderLineChart();
    },
    toDts: function(val){
      console.log( '--------------- child: watch( searchDate ) LineByTank ---------------: '+val);
      this.renderLineChart();

    },*/
    // color: function(val){
    //   console.log( '--------------- child: watch( color ) LineByTank ---------------: '+val);
    // }
  }
}
</script>
